import React, {useCallback, useState, useEffect}  from 'react'
import { CSVReader, readString } from 'react-papaparse'

import {
    dropzone
} from './MyDropzone.module.css'

import {useDropzone} from 'react-dropzone'

function MyDropzone(props) {
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
  
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
          props.callBack(readString(reader.result).data)
        }
        reader.readAsText(file)
      })
      
    }, [])
    const {getRootProps, getInputProps} = useDropzone({onDrop})
  
    return (
      <div className={dropzone} {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Click to upload timesheet report</p>
      </div>
    )
  }


  export default MyDropzone;