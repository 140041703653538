import { CSVDownloader, CSVReader, readString } from "react-papaparse";
import { Consumer, Context, Provider } from "../components/SettingsContext";
import DisplayHours, { DisplayHoursRaw } from "../components/DisplayHours";
import React, { createContext, useContext, useState } from "react";
import {
  bold,
  buttonCont,
  content,
  daterange,
  daterangeheader,
  detail,
  detail_row,
  downloadAsPdf,
  dr,
  drCont,
  dropzone,
  flipActive,
  flipButton,
  flipButtonAnchor,
  flipButtonSettings,
  flipContainer,
  header,
  littleRow,
  logo,
  padding,
  page,
  reloadButton,
  row_col_hours,
  row_col_site_name,
  row_edges,
  rowsCont,
  searchBox,
  settingsIconWrap,
  title,
  titleRow,
  titleRowTable,
  titleright,
  upload,
} from "./index.module.css";
import {
  row,
  row_col,
  row_main,
  row_site,
  row_title,
  row_value,
} from "../components/row.module.css";

import DataExport from "../components/dataExport";
import Fuse from "fuse.js";
import { GoSettings } from "@react-icons/all-files/go/GoSettings";
import MyDropzone from "../components/MyDropzone";
import Row from "../components/Row";
import Settings from "../components/Settings";
import createPDF from "../pdf";
import moment from "moment";

const axios = require("axios");
const ers_all = ["Invalid time range", "Invalid staff member"];
const queryString = require("query-string");
// const SettingsContext = createContext({});

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.byStaff = this.byStaff.bind(this);
    this.gotData = this.gotData.bind(this);
    this.setBySite = this.setBySite.bind(this);
    this.parseRows = this.parseRows.bind(this);
    this.flipState = this.flipState.bind(this);
    this.openPDF = this.openPDF.bind(this);
    this.settingsFlip = this.settingsFlip.bind(this);
    this.setSettings = this.setSettings.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.ConvertToCSV = this.ConvertToCSV.bind(this);

    this.state = {
      uploaded: false,
      settingsVisible: false,
      settings: {
        timeFormat: "dec",
        staffOrder: "lastname",
        showLeave: true
      },
      view: "staff",
    };

    this.viewFlip = { staff: "site", site: "staff" };
  }

  componentDidMount() {
    const localSettingsRaw = localStorage.getItem(
      "safetywise_timesheetSettings"
    );
    if (localSettingsRaw) {
      try {
        const localSettings = JSON.parse(localSettingsRaw);
        if (localSettings.showGeolocation === undefined) {
          localSettings.showGeolocation = true;
        }
        if (localSettings.showStaffType === undefined) {
          localSettings.showStaffType = true;
        }
        if (localSettings.showTaskType === undefined) {
          localSettings.showTaskType = true;
        }

        this.setState({ settings: localSettings });
      } catch {
        console.log("Error loading settings");
      }
    }
    let params = queryString.parse(this.props.location.search);

    var id = params?.id;
    if (id) {
      axios
        .get(`/api/get-times?id=${id}`)
        .then((res) => {
          //console.log(`statusCode: ${res.status}`);
          //console.log(res.data);
          var data = res.data;
          //console.log(data);
          this.gotData(this.ConvertToCSV(data), true);
        })
        .catch((error) => {
          alert("Invalid timesheet records data");
        });
    }
  }

  flipState(ns) {
    this.setState({ view: ns });
  }

  gotData(inc) {
    // console.log(inc)
    const dataType = inc[0][0];
    if (dataType !== "Timesheet Records") {
      alert("Invalid timesheet records file");
      console.error("Invalid timesheet records");
      // console.log(dataType)
      return;
    }
    const rows = this.parseRows(inc);
    //This sorts initial records by Date
    rows.sort(dateCompare);
    debugger;
    const byStaff = this.byStaff(rows);
    const bySite = this.setBySite(rows);
    const range = dateRange(inc);


    let sortedKeys = Object.keys(byStaff).sort();
    if (this.state.settings.staffOrder == 'lastname') {
      sortedKeys = Object.keys(byStaff).sort((a, b) => {
        return a.split(' ')[1].localeCompare(b.split(' ')[1]);
      });
    }

    const sortedStaff = {};
    for (const key of sortedKeys) {
      sortedStaff[key] = byStaff[key];
    }


    let sortedSiteKeys = Object.keys(bySite).sort();

    const sortedSite = [];
    for (const key of sortedSiteKeys) {
      sortedSite[key] = bySite[key];
    }

    for (let st of Object.keys(sortedSite)) {
      let sortedStaffKeys = Object.keys(sortedSite[st].staff).sort();

      if (this.state.settings.staffOrder == 'lastname') {
        sortedStaffKeys = Object.keys(byStaff).sort((a, b) => {
          return a.split(' ')[1].localeCompare(b.split(' ')[1]);
        });
      }

      const sortedSiteStaff = {};
      for (const key of sortedStaffKeys) {
        if (sortedSite[st].staff[key]) {
          sortedSiteStaff[key] = sortedSite[st].staff[key];
        }
      }

      sortedSite[st].staff = sortedSiteStaff;
    }


    this.setState({ hours: sortedStaff }, () => {
      this.setState({ bySite: sortedSite }, () => {
        this.setState({ range: range }, () => {
          this.setState({ uploaded: true }, () => { });
        });
      });
    });
  }

  async openPDF(sites, staff, tf) {
    if (this.state.view === "staff") {
      await createPDF("staff", staff, this.state.range, tf);
    } else {
      await createPDF("site", sites, this.state.range, tf);
    }
  }

  setBySite(rows) {
    const sites = [];

    rows.forEach((x) => {
      if (ki(sites, x.site)) {
        //site object exists
        sites[x.site].total += x.hours;
        sites[x.site].lunch += x.lunchAmount;
        x.ers.forEach((er) => {
          if (!sites[x.site].ers.includes(er)) {
            sites[x.site].ers.push(er);
          }
        });

        if (ki(sites[x.site].staff, x.staff)) {
          //staff object in site exists
          sites[x.site].staff[x.staff].records.push(x);
          sites[x.site].staff[x.staff].total += x.hours;
          sites[x.site].staff[x.staff].lunch += x.lunchAmount;

          x.ers.forEach((er) => {
            if (!sites[x.site].staff[x.staff].ers.includes(er)) {
              sites[x.site].staff[x.staff].ers.push(er);
            }
          });
        } else {
          //no staff object in site, create
          sites[x.site].staff[x.staff] = {
            total: x.hours,
            lunch: x.lunchAmount,
            records: [x],
            ers: [...x.ers],
          };
        }
      } else {
        //no site object, create
        sites[x.site] = {
          total: x.hours,
          lunch: x.lunchAmount,
          staff: {},
          ers: [...x.ers],
        };
        sites[x.site].staff[x.staff] = {
          total: x.hours,
          lunch: x.lunchAmount,
          records: [x],
          ers: [...x.ers],
        };
      }
    });

    Object.keys(sites).forEach((x) => {
      Object.keys(sites[x].staff).forEach((y) => {
        sites[x].staff[y].records = sites[x].staff[y].records.sort(
          (x, y) => +new Date(x.start) - +new Date(y.start)
        );
      });
    });

    return sites;
  }

  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var result = [];

    for (var i = 0; i < array.length; i++) {
        var line = [];
        // debugger;
        for (var index in array[i]) {
            // if (line != '') line += ','

            line.push(array[i][index]);
        }

        result.push(line);
    }

    return result;
}

  parseRows(inc, skipHeaders = false) {
    var data = [];
    data = [...inc];

    if (!skipHeaders) {
      data.shift(0);
      data.shift(0);
    } else {
      // data = inc;
    }
    console.log(data[0]);

    const hasLunch = data[0].length > 9 ? true : false;
    const newGeo = data[0].length > 11 ? true : false;
    // alert(data[0].length)
    // alert(`hasLunch: ${hasLunch}`)
    // alert(`NewGeo: ${newGeo}`)
    let keys;

    if (newGeo) {
      keys = {
        startDate: 1,
        startTime: 2,
        endDate: 4,
        endTime: 5,
        lunch: 3,
        geoStart: 10,
        geoEnd: 11,
        staffType: 12,
        taskType: 13,
        site: 7,
        notes: 6,
      };
    } else {
      if (hasLunch) {
        keys = {
          startDate: 1,
          startTime: 2,
          endDate: 4,
          endTime: 5,
          lunch: 3,
          geoEnd: 10,
          staffType: 11,
          taskType: 12,
          site: 7,
          notes: 6,
        };
      } else {
        keys = {
          startDate: 1,
          startTime: 2,
          endDate: 3,
          endTime: 4,
          geoEnd: 9,
          staffType: 10,
          taskType: 11,
          site: 6,
          notes: 5,
        };
      }
    }

    const dataOut = [];

    data.forEach((x, i) => {
      if (x.length < 10) {
        return;
      }
      const dateFormat = "D MMM YYYY h:mm a";
      const ers = [];

      let start = x[1] + " " + x[2];
      let end = x[keys["endDate"]] + " " + x[keys["endTime"]];

      let mStart = moment(start, dateFormat);
      let mEnd = moment(end, dateFormat);

      let formattedStart = mStart.format("ddd D MMM yyyy h:mm a");
      let formattedEnd = mEnd.format("ddd D MMM yyyy h:mm a");

      let staff = x[0];

      if (!mStart.isValid() || !mEnd.isValid()) {
        console.log("Invalid date");
        console.log(x);
        mStart = moment(start, "D-MMM-YYYY h:mm a");
        mEnd = moment(end, "D-MMM-YYYY h:mm a");
      }
      let hours = mEnd.diff(mStart, "hours", true);

      let lunch = x[keys["lunch"]];
      let lunchH;

      if (hasLunch) {
        let lunchAmt = parseInt(x[keys["lunch"]].split(" ")[0]);
        if (lunchAmt === 60) {
          lunchH = 1;
        } else if (lunchAmt === 30) {
          lunchH = 0.5;
        } else {
          lunchH = 0;
          lunch = "-";
        }
      }

      if (hours < 0) {
        ers.push(ers_all[0]);
      }

      if (staff.length < 1) {
        ers.push(ers_all[1]);
        staff = "-";
      }

      let geoStart;
      let geoEnd;

      if (newGeo) {
        geoStart = parseGeo(x[keys.geoStart]);
        geoEnd = parseGeo(x[keys.geoEnd]);
      } else {
        geoStart = "";
        geoEnd = parseGeo(x[keys.geoEnd]);
      }

      // const [lat,long] = parseGeo(x[keys['geo']])
      // console.log(lat,long)

      let newRow = {
        staff: staff,
        hours: hours,
        start: formattedStart,
        end: formattedEnd,
        site: x[keys.site],
        ers: ers,
        lunch: lunch || "-",
        lunchAmount: lunchH || 0,
        netHours: hours - lunchH,
        // geo:[lat,long],
        geoStart: geoStart,
        geoEnd: geoEnd,
        notes: x[keys.notes],
        staffType: x[keys.staffType],
        taskType: x[keys.taskType],
      };

      dataOut.push(newRow);
    });

    return dataOut;
  }

  byStaff(rows) {
    let staff = {};
    rows.forEach((x) => {
      if (ki(staff, x.staff)) {
        staff[x.staff].total += x.hours;
        staff[x.staff].lunch += x.lunchAmount;
        staff[x.staff].records.push(x);
        if (x.ers) {
          x.ers.forEach((er) => {
            if (!staff[x.staff].ers.includes(er)) {
              staff[x.staff].ers.push(er);
            }
          });
        }
      } else {
        staff[x.staff] = {
          total: x.hours,
          lunch: x.lunchAmount,
          records: [x],
          ers: [...x.ers],
        };
      }
    });

    Object.keys(staff).forEach((x) => {
      staff[x].records = staff[x].records.sort(
        (x, y) => +new Date(x.start) - +new Date(y.start)
      );
    });
    return staff;
  }

  settingsFlip() {
    // console.log("Flipping settings")
    this.setState({ settingsVisible: !this.state.settingsVisible }, () => {
      // console.log(this.state.settingsVisible)
    });
  }

  clearFilter(props, filterValue, setFilterValue, setStaff, setSite) {
    setStaff("");
    setSite("");
    setFilterValue("");
  }

  setSettings(newSettings) {
    // console.log("Setting settings")
    this.setState({ settings: newSettings }, () => {
      // console.log(this.state.settings)
      localStorage.setItem(
        "safetywise_timesheetSettings",
        JSON.stringify(this.state.settings)
      );
    });
  }

  render() {
    return (
      <div className={page}>
        <Provider value={this.state.settings}>
          <title>Fortherecord - Timesheet Organiser</title>

          <div className={header}>
            <img
              onClick={() =>
                (window.location = "https://app.safetywise.co.nz/")
              }
              className={logo}
              src={"logo2.png"}
            ></img>

          </div>

          <div className={content}>
            <table className={titleRowTable}>
              <tbody>
                <tr>
                  <td style={{ width: "250px" }} className={title}>
                    Timesheet Organiser
                  </td>
                  {this.state.range && (
                    <td className={titleright} style={{ paddingRight: "20px" }}>
                      <span className={row_title}>Date Range </span>
                      <span className={bold}>From </span>
                      <span className={daterange}>
                        {this.state.range?.start}
                      </span>
                      <span className={bold}>&nbsp;to&nbsp;</span>
                      <span className={daterange}>{this.state.range?.end}</span>
                    </td>
                  )}
                  <td style={{ width: "100px" }} className={titleright}>
                    <ReloadButton state={this.state.uploaded}></ReloadButton>
                  </td>
                </tr>
              </tbody>
            </table>

            {!this.state.uploaded ? (
              <div className={upload}>
                <MyDropzone
                  callBack={this.gotData}
                  className={dropzone}
                  accept=".csv"
                ></MyDropzone>
              </div>
            ) : (
              <div>
                <Settings
                  visible={this.state.settingsVisible}
                  callback={this.settingsFlip}
                  settings={this.state.settings}
                  settingsCallback={this.setSettings}
                />

                <ViewRows
                  state={this.state}
                  settingsFlip={this.settingsFlip}
                  clearFilter={this.clearFilter}
                  pdf={this.openPDF}
                  cb={this.flipState}
                  view={this.state.view}
                  range={this.state.range}
                  hours={this.state.hours}
                  site={this.state.bySite}
                  settings={this.state.settings}
                ></ViewRows>
              </div>
            )}
          </div>
        </Provider>
      </div>
    );
  }
}

const SearchBox = (props) => {
  // console.log(props)
  const placeHolder =
    props.state === "staff"
      ? "Filter by staff member name"
      : "Filter by site name";
  const [lastState, setLastState] = useState("staff");
  //const [value, setValue] = useState("");
  if (props.state !== lastState) {
    props.setFilterValue("");
    setLastState(props.state);
    props.setStaff("");
    props.setSite("");
  }
  const handleChange = (evt) => {
    props.setFilterValue(evt);
    // console.log(evt)
    if (props.state === "staff") {
      props.setStaff(evt);
    } else {
      props.setSite(evt);
    }
  };

  return (
    <input
      className={searchBox}
      placeholder={placeHolder}
      value={props.filterValue}
      onChange={(x) => handleChange(x.target.value)}
    ></input>
  );
};

const ReloadButton = (props) => {
  if (!props.state) {
    return <div></div>;
  }

  return (
    <button onClick={() => window.location.reload()} className={reloadButton}>
      Clear All
    </button>
  );
};

const ViewRows = (props) => {
  // console.log(props.hours)

  const settings = useContext(Context);
  console.log(settings);

  const [staffSearch, setStaffSearch] = useState();
  const [siteSearch, setSiteSearch] = useState();

  const staff = applySearch(staffSearch, props.hours, props.settings, 'staff');
  const sites = applySearch(siteSearch, props.site, props.settings, 'site');

  let sortedKeys = Object.keys(staff).sort();

  if (props.settings.staffOrder == 'lastname') {
    sortedKeys = Object.keys(staff).sort((a, b) => {
      return a.split(' ')[1].localeCompare(b.split(' ')[1]);
    });
  }
  debugger;
  const sortedStaffData = {};
  for (const key of sortedKeys) {
    if (!props.settings.showLeave) {
      var newStaff = JSON.parse(JSON.stringify(staff));;
      newStaff[key].records = newStaff[key].records.filter(c => c.site.indexOf('Leave -') < 0);
      //Sum Total Hours
      newStaff[key].total = newStaff[key].records.reduce(function (accumulator, currentRecord) {
        return accumulator + currentRecord.hours;
      }, 0);

      //Sum Total Lunch
      newStaff[key].lunch = newStaff[key].records.reduce(function (accumulator, currentRecord) {
        return accumulator + currentRecord.lunchAmount;
      }, 0);

      sortedStaffData[key] = newStaff[key];
    } else {
      sortedStaffData[key] = staff[key];

    }
  }

  let sortedSiteKeys = Object.keys(sites).sort();
  if (!props.settings.showLeave) {
    sortedSiteKeys = sortedSiteKeys.filter(c => c.indexOf('Leave -') < 0);
  }

  const sortedSite = [];
  for (const key of sortedSiteKeys) {
    sortedSite[key] = sites[key];
  }

  for (let st of Object.keys(sortedSite)) {
    let sortedStaffKeys = Object.keys(sortedSite[st].staff).sort();
    if (props.settings.staffOrder == 'lastname') {
      sortedStaffKeys = Object.keys(sortedSite[st].staff).sort((a, b) => {
        return a.split(' ')[1].localeCompare(b.split(' ')[1]);
      });
    }

    const sortedSiteStaff = {};
    for (const key of sortedStaffKeys) {
      if (sortedSite[st].staff[key]) {
        sortedSiteStaff[key] = sortedSite[st].staff[key];
      }
    }

    sortedSite[st].staff = sortedSiteStaff;
  }


  const [filterValue, setFilterValue] = useState("");
  return (
    <div>
      <div className={drCont} style={{ marginTop: "10px" }}>
        <div className={row_title}></div>
        <div className={row_edges} >
          <FlipButtons state={props.view} cb={props.cb}></FlipButtons>
        </div>
        {props.state.uploaded && (
          <div style={{ display: "inline-block", float: "right" }}>
            <SettingsIcon callback={props.settingsFlip} />
          </div>
        )}
      </div>
      <table className={titleRowTable} style={{ marginTop: "-20px" }}>
        <tbody>
          <tr>
            <td>
              <div style={{ display: "contents" }}>
                <SearchBox
                  state={props.view}
                  setStaff={setStaffSearch}
                  setSite={setSiteSearch}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                ></SearchBox>
              </div>
              <div style={{ display: "inline-flex" }}>
                <ClearFilterButton
                  props={props}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  setStaff={setStaffSearch}
                  setSite={setSiteSearch}
                  callback={props.clearFilter}
                />
              </div>
            </td>
            <td>
              <div className={downloadAsPdf}>
                Download as&nbsp;
                <a
                  onClick={() => props.pdf(sortedSite, sortedStaffData, settings.timeFormat)}
                  className={flipButtonAnchor}
                >
                  PDF
                </a>
                &nbsp;or&nbsp;
                <DataExport
                  sites={sortedSite}
                  staff={sortedStaffData}
                  settings={settings}
                  view={props.view}
                ></DataExport>
              </div>

            </td>
          </tr>
        </tbody>
      </table>

      {props.view === "staff" ? (
        <Hours
          data={sortedStaffData}
          range={props.range}
          showGeolocation={settings.showGeolocation}
          showStaffType={settings.showStaffType}
          showTaskType={settings.showTaskType}
        ></Hours>
      ) : (
        <SiteView
          data={sortedSite}
          range={props.range}
          showGeolocation={settings.showGeolocation}
          showStaffType={settings.showStaffType}
          showTaskType={settings.showTaskType}
        ></SiteView>
      )}
    </div>
  );
};

function applySearch(query, object, settings, type) {
  if (!query) {
    return object;
  }
  let out = {};
  const fuse = new Fuse(Object.keys(object), { threshold: 0.2 });
  const results = fuse.search(query).map((x) => x.item);
  const keys = Object.keys(object).filter((x) => results.includes(x));
  keys.forEach((x) => (out[x] = object[x]));

  return out;
}

const FlipButtons = (props) => {
  const inactive = flipButton;
  const active = `${flipButton} ${flipActive}`;
  return (
    <div className={flipContainer} >
      <a
        onClick={() => props.cb("staff")}
        className={props.state === "staff" ? active : inactive}
      >
        Organise by Staff Member
      </a>
      <a
        onClick={() => props.cb("site")}
        className={props.state === "site" ? active : inactive}
      >
        Organise by Site
      </a>
    </div>
  );
};

const SiteView = (props) => {
  const range = props.range;
  const sites = props.data;

  return (
    <div>
      {k(sites).map((site, i) => (
        <Site
          name={site}
          data={sites[site]}
          key={i}
          showGeolocation={props.showGeolocation}
          showStaffType={props.showStaffType}
          showTaskType={props.showTaskType}
        ></Site>
      ))}
    </div>
  );
};

const Site = (props) => {
  return (
    <div className={`${row} ${row_site}`}>
      <div className={row_main}>
        <div className={row_col_site_name}>
          <div className={row_title}>Site name</div>
          <div className={row_value}>{props.name || "-"}</div>
        </div>

        <div className={row_col_hours}>
          <div className={row_title}>Gross Hours</div>
          <div className={row_value}>
            <DisplayHours hours={Math.round(props.data.total * 100) / 100} />
          </div>
        </div>

        <div className={row_col_hours}>
          <div className={row_title}>Lunch</div>
          <div className={row_value}>
            <DisplayHours hours={Math.round(props.data.lunch * 100) / 100} />
          </div>
        </div>

        <div className={row_col_hours}>
          <div className={row_title}>Net hours</div>
          <div className={row_value}>
            <DisplayHours
              hours={
                Math.round((props.data.total - props.data.lunch) * 100) / 100
              }
            />
          </div>
        </div>
      </div>

      <div className={rowsCont}>
        <Hours
          data={props.data.staff}
          siteMode={true}
          showGeolocation={props.showGeolocation}
          showStaffType={props.showStaffType}
          showTaskType={props.showTaskType}
        ></Hours>
      </div>
    </div>
  );
};

const Hours = (props) => {
  const data = props.data;
  const range = props.range;
  // if(!Object.keys(data.length)){
  //   return("No results")
  // }
  return (
    <div>
      <div className={rowsCont}>
        {Object.keys(data)
          .map((x, i) => (
            <Row
              hours={data[x]}
              staff={x}
              key={i}
              siteMode={props.siteMode || false}
              showGeolocation={props.showGeolocation}
              showStaffType={props.showStaffType}
              showTaskType={props.showTaskType}
            ></Row>
          ))}
      </div>
    </div>
  );
};

const k = (o) => {
  return Object.keys(o);
};

const ki = (o, i) => {
  return k(o).includes(i);
};

function dateRange(inc) {
  const data = [...inc];
  const headers = data[1];
  // console.log(headers)
  data.shift();
  data.shift();

  const hasLunch = headers.includes("Lunch Time");
  const newGeo = headers.includes("Start Geolocation");

  // return(`${hasLunch} ${newGeo}`)
  // console.log(hasLunch)
  // console.log(newGeo)
  let range_start = false;
  let range_end = false;

  let endDateIndex;
  let endTimeIndex;

  if (hasLunch) {
    endDateIndex = 4;
    endTimeIndex = 5;
  } else {
    endDateIndex = 3;
    endTimeIndex = 4;
  }

  // if(data[0].length === 11){
  //   endDateIndex = 4;
  //   endTimeIndex = 5;
  // }else{
  //   endDateIndex = 3;
  //   endTimeIndex = 4;
  // }

  // console.log(endDateIndex);
  // console.log(endTimeIndex)

  // console.log(data[0][endDateIndex])
  // console.log(data[0][endTimeIndex])
  // console.log(data[0])

  data.forEach((x) => {
    if (x.length < 10) {
      return;
    }
    let dateFormat = "ddd D MMM YYYY h:mm a";
    let dateFormat2 = "D-MMM-YYYY h:mm a";

    let mStart = moment(x[1] + " " + x[2], dateFormat);
    let mEnd = moment(x[endDateIndex] + " " + x[endTimeIndex], dateFormat);

    if (!mStart.isValid() || !mEnd.isValid()) {
      mStart = moment(x[1] + " " + x[2], dateFormat2);
      mEnd = moment(x[endDateIndex] + " " + x[endTimeIndex], dateFormat2);
    }

    mStart = mStart.unix();
    mEnd = mEnd.unix();

    if (!range_start || mStart < range_start) {
      range_start = mStart;
    }
    if (!range_end || mEnd > range_end) {
      range_end = mEnd;
    }
  });

  let out = {
    start: moment.unix(range_start).format("ddd DD MMM YYYY"),
    end: moment.unix(range_end).format("ddd DD MMM YYYY"),
  };
  return out;
}

function dateCompare(a, b) {
  const ma = moment(a.start, "ddd D MMM YYYY h:mm a").unix();
  const mb = moment(b.start, "ddd D MMM YYYY h:mm a").unix();

  if (ma < mb) {
    return -1;
  } else if (ma > mb) {
    return 1;
  }
  return 0;
}

function parseGeo(raw) {
  // console.log(raw)
  let a = raw.split(",");
  return [a[0].split(":")[1].trim(), a[1].split(":")[1].trim()];
  // return(['a','a'])
}

const SettingsIcon = ({ callback }) => {
  return (
    <button className={flipButtonSettings} onClick={() => callback()}>
      {/* <GoSettings></GoSettings> */}
      Format Settings
    </button>
  );
};

const ClearFilterButton = ({
  props,
  filterValue,
  setFilterValue,
  setStaff,
  setSite,
  callback,
}) => {
  return (
    <div className={downloadAsPdf}>
      <a
        onClick={() =>
          callback(props, filterValue, setFilterValue, setStaff, setSite)
        }
        className={flipButtonAnchor}
      >
        Clear Filter
      </a>
    </div>
  );
};

export default Index;
