import * as xl from 'xlsx/xlsx.mjs';

import React, { useEffect, useState } from "react";
import {
    flipButton,
    flipButtonAnchor,
} from '../pages/index.module.css'

import { DisplayHoursWithFormat } from './DisplayHours'

// import xlsx from "json-as-xlsx";






const DataExport = ({ sites, settings, staff, view }) => {

    const handleExport = () => {
        let workbook = xl.utils.book_new();

        workbook.Props = {
            Title: 'Timesheet Report',
            Subject: 'Timesheet Report',
            Author: 'SafetyWise',
            CreatedDate: new Date(),
        };

        let worksheetStaffData = [];
        let worksheetSiteData = [];


        Object.keys(staff).forEach(_staff => {
            worksheetStaffData = [...worksheetStaffData, ...table_staffMember(staff[_staff], _staff, settings)];
        })
        Object.keys(sites).forEach(_site => {
            worksheetSiteData = [...worksheetSiteData, ...table_site(sites[_site], _site, settings)]
        })


        let worksheetStaff = xl.utils.aoa_to_sheet(worksheetStaffData);
        let worksheetSite = xl.utils.aoa_to_sheet(worksheetSiteData);

        xl.utils.book_append_sheet(workbook, worksheetStaff, "Staff")
        xl.utils.book_append_sheet(workbook, worksheetSite, "Site")

        var wscols = [
            { wch: 25 },
            { wch: 25 },
            { wch: 25 }, //? 'Start Time'
            { wch: 25 }, //? 'End Time'
            { wch: 25 }, //? 'Site'
            { wch: 25 }, //? 'Notes'
            { wch: 25 }, //? 'Staff Type'
            { wch: 25 }, //? 'Task Type'
            { wch: 25 }, //? 'Start Geo'
            { wch: 25 }, //? 'End Geo'
            { wch: 25 }, //? 'Gross Hours'
            { wch: 25 }, //? 'Lunch'
            { wch: 25 }, //? 'Net Hours'
        ];

        worksheetStaff['!cols'] = wscols;
        worksheetSite['!cols'] = wscols;


        const out = xl.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' })
        let url = window.URL.createObjectURL(new Blob([s2ab(out)], { type: 'application/octet-stream' }))

        // console.log(staff)
        console.log(worksheetSiteData);
        console.log(worksheetStaffData);

        // return;
        let a = document.createElement('a');
        a.href = url;
        a.download = 'Report.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);

    }


    return ( <
        a onClick = {
            () => handleExport()
        }
        className = { flipButtonAnchor } > XSLX < /a>
    )
}

function s2ab(s) {
    const buf = new ArrayBuffer(s.length)

    const view = new Uint8Array(buf)

    for (let i = 0; i !== s.length; ++i)
        view[i] = s.charCodeAt(i) & 0xFF

    return buf
}

const table_staffMember = (staff, name, settings) => {
    const out = [];
    out.push([name, `Total: ${DisplayHoursWithFormat(staff.total, settings)}`])
    out.push([])
    out.push(['', 'Start Time', 'End Time', 'Site', 'Notes', 'Staff Type', 'Task Type', 'Start Geo', 'End Geo', 'Gross Hours', 'Lunch', 'Net Hours'])
    staff.records.forEach(record => {
        out.push(['', record.start, record.end, record.site, record.notes, record.staffType, record.taskType, record.geoStart, record.geoEnd, DisplayHoursWithFormat(record.hours, settings), record.lunch, DisplayHoursWithFormat(record.netHours, settings)])
    })

    return out;
}

const table_site = (site, name, settings) => {
    const out = [];
    out.push([name, `Total: ${DisplayHoursWithFormat(site.total, settings)}`])
    out.push([])
    Object.keys(site.staff).forEach((_staff) => {

        out.push([''])
        out.push(['', _staff, `Total: ${DisplayHoursWithFormat(site.staff[_staff].total, settings)}`])

        out.push(['', '', 'Start Time', 'End Time', 'Site', 'Notes', 'Staff Type', 'Task Type', 'Start Geo', 'End Geo', 'Gross Hours', 'Lunch', 'Net Hours'])

        site.staff[_staff].records.forEach(record => {
            out.push(['', '', record.start, record.end, record.site, record.notes, record.staffType, record.taskType, record.geoStart, record.geoEnd, DisplayHoursWithFormat(record.hours, settings), record.lunch, DisplayHoursWithFormat(record.netHours, settings)])

        })
    })

    // if(settings.siteOrder == 'asc') {
    //   out = out.sort((a, b) =>
    //     a.site > b.site ? 1 : -1,
    //   );
    // }else {
    //   out = out.sort((a, b) =>
    //     a.site > b.site ? 1 : 1,
    //   );
    // }
    return out;
}


const cleanString = (input) => {
    const replaceMap = {
        "\/": "\|",
        "\\": "\|",
        "[": "(",
        "]": ")",
        "?": "",
        "*": ""
    }

    let output = input;
    Object.keys(replaceMap).forEach(_pre => {
        output = output.replace(_pre, replaceMap[_pre]);
    })
    output = output.trim().split('').slice(0, 10).join('');

    return output;

}


export default DataExport;