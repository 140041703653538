// extracted by mini-css-extract-plugin
export var detailHeader = "row-module--detailHeader--Sy2Gk";
export var er_col = "row-module--er_col--WXYjd";
export var er_row = "row-module--er_row--zCFB4";
export var er_title = "row-module--er_title--hlZMz";
export var headers = "row-module--headers--voMxe";
export var row = "row-module--row--LGZKh";
export var row_col = "row-module--row_col--HbLna";
export var row_col_hours = "row-module--row_col_hours--3ByC7";
export var row_col_map = "row-module--row_col_map--lGkZL";
export var row_col_staff_member = "row-module--row_col_staff_member--JhjYo";
export var row_col_staff_type = "row-module--row_col_staff_type--Y7tXL";
export var row_col_time = "row-module--row_col_time--DncEr";
export var row_main = "row-module--row_main--Jrwmf";
export var row_site = "row-module--row_site--2Lt8I";
export var row_title = "row-module--row_title--o7k4b";
export var row_value = "row-module--row_value--gvHFO";
export var rowsCont = "row-module--rowsCont--zjLbo";
export var svgAlert = "row-module--svgAlert--QREbD";