import React, {useState} from 'react'

import {
    toggle

} from './DetailToggle.module.css'
const DetailToggle = (props) => {
    return(
      <a className={toggle}onClick={()=>props.fn(!props.state)}>
        {
          !props.state ? 
          "Show entries"
          :
          "Hide entries"
        }
      </a>
     
    )
  }

  export default DetailToggle;