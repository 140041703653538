// extracted by mini-css-extract-plugin
export var detail = "Detail-module--detail--iDVAL";
export var detail_alert = "Detail-module--detail_alert--KA+iF";
export var detail_hours = "Detail-module--detail_hours--lN7lx";
export var detail_map = "Detail-module--detail_map--lI67Q";
export var detail_row = "Detail-module--detail_row--v+o0c";
export var f2 = "Detail-module--f2--p2ukQ";
export var f25 = "Detail-module--f25--9Cj9F";
export var f3 = "Detail-module--f3--vCssy";
export var geoLink = "Detail-module--geoLink--sX5IS";
export var row_col_hours = "Detail-module--row_col_hours--fr8Ss";
export var row_col_time = "Detail-module--row_col_time--jJP0A";
export var text_columns = "Detail-module--text_columns--Ktb8g";